import { Empty } from 'antd'
import Page from '../components/page'

const ErrorPage = () => {
	return (
		<Page>
			<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', justifyItems: 'center' }}>
				<Empty
					description='Something probably went wrong!'
					image='/img/404.svg'
					imageStyle={{ height: '500px', marginBottom: '24px' }}
					style={{ marginTop: '128px' }}
				/>
			</div>
		</Page>
	)
}

export default ErrorPage
